
import { defineComponent, reactive, computed, toRaw } from 'vue';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
// require defined typo
import { FormState } from '../../model';

export default defineComponent({
  emits: ['next-step', 'prev'],
  setup(_, { emit }) {
    const store = useStore();

    const savedStepForm = computed<FormState>(() => store.getters['stepForm/step']);

    const state = reactive({
      loading: false,
    });
    const modelRef = reactive({
      password: undefined,
    });
    const rulesRef = reactive({
      password: [{ required: true, message: '需要支付密码才能进行支付' }],
    });

    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);
    const handleSubmit = (e: Event) => {
      e.preventDefault();
      state.loading = true;

      validate()
        .then(() => {
          const password = toRaw(modelRef).password;

          store
            .dispatch('stepForm/submitStepForm', {
              ...savedStepForm.value,
              password,
            })
            .then(() => {
              emit('next-step');
            })
            .finally(() => {
              state.loading = true;
            });
        })
        .catch(err => {
          state.loading = false;
          console.error('err', err);
          console.warn('validateInfos', toRaw(validateInfos));
        });
    };
    const handlePrev = (e: Event) => {
      e.preventDefault();
      emit('prev');
      // router.push('/form/step-form/info');
    };

    return {
      state,

      savedStepForm,

      modelRef,
      validateInfos,
      resetFields,

      handleSubmit,
      handlePrev,
    };
  },
});
